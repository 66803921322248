














































































// Core
import { Component, Prop, Vue } from 'vue-property-decorator'

// Interfaces
import { User } from '@store/users/interfaces'
import {namespace} from "vuex-class";

const NSUsers = namespace('usersModule')

@Component({name: 'UserCard'})
export default class UserCardComponent extends Vue {
  @NSUsers.Getter('rolesMap')
  private roles!: { [key: number]: string }

  @Prop({ default: () => null }) private user!: User

  @Prop(Function) private deactivateUser!: (user: User) => void

  @Prop(Function) private activateUser!: (user: User) => void

  get userRoles(){
    return new Set(this.user.projects.map(el => el.roleId))
  }
}
